export const navItems = [
    {
      name: "Find a Hospital",
      link: "#",
      submenu: [
        {
          title: "The North & Scotland",
          menus: [
            { name: "The Alexandra Hospital", link: "#" },
            { name: "Cheadle, Greater Manchester", link: "#" },
            { name: "Ross Hall Hospital", link: "#" },
            { name: "Thornbury Hospital", link: "#" },
            { name: "Albyn Hospital", link: "#" },
            { name: "The Highfield Hospital", link: "#" },
          ],
        },
        {
          title: "London & South East",
          menus: [
            { name: "The Blackheath Hospital", link: "#" },
            { name: "The Clementine Churchill Hospital", link: "#" },
            { name: "The Saxon Clinic", link: "#" },
            { name: "The Chiltern Hospital", link: "#" },
            { name: "The Princess Margaret Hospital", link: "#" },
          ],
        },
        {
          title: "Central & South West",
          menus: [
            { name: "The Priory Hospital", link: "#" },
            { name: "The Park Hospital", link: "#" },
            { name: "The Meriden Hospital", link: "#" },
            { name: "Bath Clinic", link: "#" },
            { name: "The Ridgeway Hospital", link: "#" },
          ],
        },
      ],
    },
    {
      name: "Find a Specialist",
      link: "#",
      submenu: [
        {
          title: "Orthopaedics (Bones & joints)",
          menus: [
            { name: "Hip surgery", link: "#" },
            { name: "Knee surgery", link: "#" },
            { name: "Shoulder surgery", link: "#" },
            { name: "Spine surgery", link: "#" },
          ],
        },
        {
          title: "Gynaecology",
          menus: [
            { name: "Maternity care", link: "#" },
            { name: "Gynaecological surgery", link: "#" },
            { name: "Menopause services", link: "#" },
          ],
        },
        {
          title: "General Surgery",
          menus: [
            { name: "Appendectomy", link: "#" },
            { name: "Bariatric surgery", link: "#" },
            { name: "Gallbladder surgery", link: "#" },
          ],
        },
      ],
    },
    {
      name: "Find a Treatment",
      link: "#",
      submenu: [
        {
          title: "Surgical Procedures",
          menus: [
            { name: "Hip replacement surgery", link: "#" },
            { name: "Knee replacement surgery", link: "#" },
            { name: "Gallbladder removal surgery", link: "#" },
          ],
        },
        {
          title: "Consultations",
          menus: [
            { name: "Dermatology consultation", link: "#" },
            { name: "Colonoscopy procedure", link: "#" },
            { name: "Hernia repair", link: "#" },
          ],
        },
        {
          title: "Other Treatments",
          menus: [
            { name: "Cataract surgery", link: "#" },
            { name: "Knee arthroscopy", link: "#" },
          ],
        },
      ],
    },
    {
      name: "Scans & Tests",
      link: "#",
      submenu: [
        {
          title: "Imaging",
          menus: [
            { name: "CT scan", link: "#" },
            { name: "MRI scan", link: "#" },
            { name: "Ultrasound scan", link: "#" },
          ],
        },
        {
          title: "Diagnostic",
          menus: [
            { name: "X-ray", link: "#" },
            { name: "Nuclear medicine", link: "#" },
            { name: "PET CT scan", link: "#" },
          ],
        },
      ],
    },
    {
      name: "Going Private",
      link: "#",
      submenu: [
        {
          title: "Insurance",
          menus: [
            { name: "Insurance Options", link: "#" },
            { name: "Private health insurance", link: "#" },
          ],
        },
        {
          title: "Costs & Payments",
          menus: [
            { name: "Cost estimations", link: "#" },
            { name: "Payment plans", link: "#" },
          ],
        },
        {
          title: "Private Clinics",
          menus: [
            { name: "Find a clinic", link: "#" },
            { name: "Book an appointment", link: "#" },
          ],
        },
      ],
    },
    {
      name: "Other Services",
      link: "#",
      submenu: [
        {
          title: "Health Subscriptions",
          menus: [
            { name: "MyWay", link: "#" },
            { name: "Subscription options", link: "#" },
          ],
        },
        {
          title: "GP Services",
          menus: [
            { name: "Private GP services", link: "#" },
            { name: "Book a GP appointment", link: "#" },
          ],
        },
        {
          title: "Corporate Healthcare",
          menus: [
            { name: "Corporate health assessments", link: "#" },
            { name: "Employee wellness programs", link: "#" },
          ],
        },
        {
          title: "Eye Care Scotland",
          menus: [
            { name: "Eye care services", link: "#" },
            { name: "Eye health consultations", link: "#" },
          ],
        },
        {
          title: "Health Assessments",
          menus: [
            { name: "Full body health check", link: "#" },
            { name: "Mental health assessment", link: "#" },
          ],
        },
      ],
    },
    {
      name: "Careers",
      link: "#",
    },
  ];
  