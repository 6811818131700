import { motion, stagger, useAnimate, useInView } from "framer-motion";
import { useEffect } from "react";

const TypewriterEffect = ({
  words,
  className,
  cursorClassName
}) => {
  // split text inside of words into array of characters, but keep spaces between words
  const wordsArray = words.map((word) => {
    return {
      ...word,
      text: word.text.split(""), // split by characters
    };
  });

  const [scope, animate] = useAnimate();
  const isInView = useInView(scope);
  useEffect(() => {
    if (isInView) {
      animate("span", {
        display: "inline-block",
        opacity: 1,
        width: "fit-content",
      }, {
        duration: 0.3,
        delay: stagger(0.1),
        ease: "easeInOut",
      });
    }
  }, [isInView]);

  const renderWords = () => {
    return (
      <motion.div ref={scope} className="inline">
        {wordsArray.map((word, idx) => {
          return (
            <div key={`word-${idx}`} className="inline-block">
              {word.text.map((char, index) => (
                <motion.span
                  initial={{}}
                  key={`char-${index}`}
                  className={`opacity-0 hidden ${word.className}`}>
                  {char}
                </motion.span>
              ))}
              {/* Add a space after each word */}
              {idx < wordsArray.length - 1 && (
                <motion.span
                  key={`space-${idx}`}
                  className="opacity-0 hidden">
                  &nbsp;
                </motion.span>
              )}
            </div>
          );
        })}
      </motion.div>
    );
  };

  return (
    <div
      className={`text-base sm:text-xl md:text-3xl lg:text-5xl font-bold text-center ${className}`}>
      {renderWords()}
      <motion.span
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.8,
          repeat: Infinity,
          repeatType: "reverse",
        }}
        className={`inline-block rounded-sm w-[4px] h-4 md:h-6 lg:h-10 bg-blue-500 ${cursorClassName}`}
      />
    </div>
  );
};

const TypewriterEffectSmooth = ({
  words,
  className,
  cursorClassName
}) => {
  // split text inside of words into array of characters, but keep spaces between words
  const wordsArray = words.map((word) => {
    return {
      ...word,
      text: word.text.split(""), // split by characters
    };
  });

  const renderWords = () => {
    return (
      <div>
        {wordsArray.map((word, idx) => {
          return (
            <div key={`word-${idx}`} className="inline-block">
              {word.text.map((char, index) => (
                <span
                  key={`char-${index}`}
                  className={` ${word.className}`}>
                  {char}
                </span>
              ))}
              {/* Add a space after each word */}
              {idx < wordsArray.length - 1 && (
                <span key={`space-${idx}`}>&nbsp;</span>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={`flex space-x-1 my-6 ${className}`}>
      <motion.div
        className="overflow-hidden pb-2"
        initial={{
          width: "0%",
        }}
        whileInView={{
          width: "fit-content",
        }}
        transition={{
          duration: 2,
          ease: "linear",
          delay: 1,
        }}>
        <div
          className="text-xs sm:text-base md:text-xl lg:text-3xl xl:text-5xl font-bold"
          style={{
            whiteSpace: "nowrap",
          }}>
          {renderWords()}{" "}
        </div>
      </motion.div>
      <motion.span
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.8,
          repeat: Infinity,
          repeatType: "reverse",
        }}
        className={`block rounded-sm w-[4px] h-4 sm:h-6 xl:h-12 bg-blue-500 ${cursorClassName}`}
      />
    </div>
  );
};

export { TypewriterEffect, TypewriterEffectSmooth };
