import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faPhoneAlt, faBars, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { navItems } from "../Scripts/navlinks";
import DropdownDialog from "./DropdownDialog";

function Navbar() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogItems, setDialogItems] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [activeSubAccordion, setActiveSubAccordion] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);  // Track mobile menu state

  const toggleDialog = (submenu) => {
    if (submenu) {
      setDialogItems(submenu);
      setDialogOpen((prevState) => !prevState);
    }
  };

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const toggleSubAccordion = (parentIndex, subIndex) => {
    if (activeSubAccordion === `${parentIndex}-${subIndex}`) {
      setActiveSubAccordion(null); // Close if clicked again
    } else {
      setActiveSubAccordion(`${parentIndex}-${subIndex}`); // Open clicked submenu
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen); // Toggle mobile menu visibility
  };

  return (
    <>
      {/* Navbar */}
      <div className="bg-white relative items-center justify-center md:pt-4 w-full z-50">
        <div className="flex justify-between items-center w-full py-2 px-10">
          {/* Logo */}
          <h1 className="flex items-center space-x-3">
            <Link to="/">
              <img src="/grafton_logo.png" alt="logo" className="h-12 md:h-16 w-auto" />
            </Link>
          </h1>

          {/* Mobile Toggle Button */}
          <div className="md:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-xl text-black hover:text-blue-500 focus:outline-none"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>

          {/* Other Buttons (Hidden on Mobile) */}
          <div className="hidden md:flex items-center space-x-6">
            <button className="bg-white text-black px-6 py-4 rounded-lg hover:bg-blue-100 hover:shadow-md transition-all duration-300 flex items-center">
              <FontAwesomeIcon icon={faCommentDots} className="text-xl mr-4" />
              <div className="flex flex-col text-left">
                <span className="font-semibold">Chat with us</span>
                <span className="text-sm">Here to help</span>
              </div>
            </button>

            <button className="bg-white text-black px-6 py-4 rounded-lg hover:bg-blue-100 hover:shadow-md transition-all duration-300 flex items-center">
              <FontAwesomeIcon icon={faPhoneAlt} className="text-xl mr-4" />
              <div className="flex flex-col text-left">
                <span className="font-semibold">Call us</span>
                <span className="text-sm">0034 224 2133</span>
              </div>
            </button>

            <Link to="/system">
              <button className="bg-[#468ee0] text-white font-semibold text-lg px-6 py-4 rounded-xl shadow-sm hover:bg-blue-600 hover:shadow-md transition-all duration-300 w-full">
                Access System
              </button>
            </Link>
          </div>
        </div>

        {/* Mobile Menu - Sidebar overlay */}
        {mobileMenuOpen && (
          <div className="absolute top-16 left-0 w-full h-screen bg-white z-50 shadow-lg md:hidden">
            <div className="px-4 py-4">
              {navItems.map((item, index) => (
                <div key={index} className="mb-2">
                  <button
                    className="w-full flex justify-between items-center text-lg font-medium text-gray-700 hover:text-blue-600"
                    onClick={() => toggleAccordion(index)}
                  >
                    {item.name}
                    {item.submenu && (
                      <span>{activeAccordion === index ? "−" : "+"}</span>
                    )}
                  </button>
                  {activeAccordion === index && item.submenu && (
                    <div className="mt-2 space-y-2 pl-4">
                      {item.submenu.map((subItem, subIndex) => (
                        <div key={subIndex}>
                          <button
                            className="w-full flex justify-between items-center text-sm font-medium text-gray-700 hover:text-blue-600"
                            onClick={() => toggleSubAccordion(index, subIndex)}
                          >
                            {subItem.title}
                            <span>{activeSubAccordion === `${index}-${subIndex}` ? "−" : "+"}</span>
                          </button>
                          {activeSubAccordion === `${index}-${subIndex}` && (
                            <div className="mt-2 pl-4">
                              {subItem.menus && subItem.menus.map((subMenuItem, subMenuIndex) => (
                                <Link
                                  key={subMenuIndex}
                                  to={subMenuItem.link}
                                  className="block text-gray-600 hover:text-blue-500"
                                >
                                  {subMenuItem.name}
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Second Row: Navigation Links (Desktop) */}
        <div className="hidden md:flex justify-between space-x-8 px-20">
          {navItems.map((item, index) => (
            <div
              key={index}
              className="relative"
              onClick={() => item.submenu && toggleDialog(item.submenu)} // Handle click to toggle dropdown
            >
              <Link
                to={item.link}
                className="text-sm font-bold text-black hover:bg-blue-100 rounded-full flex items-center space-x-2 px-4 py-5"
              >
                <span>{item.name}</span>
                {item.submenu && (
                  <FontAwesomeIcon
                    icon={activeAccordion === index ? faChevronUp : faChevronDown}
                    className={`transition-transform duration-300`}
                  />
                )}
              </Link>
              {item.submenu && dialogOpen && (
                <div className="absolute left-0 w-full bg-white shadow-lg z-10">
                  <div className="flex flex-col">
                    {item.submenu.map((subItem, subIndex) => (
                      <Link
                        key={subIndex}
                        to={subItem.link}
                        className="px-4 py-2 hover:bg-gray-100"
                      >
                        {subItem.name}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <DropdownDialog
          isOpen={dialogOpen}
          submenu={dialogItems}
        />
      </div>
    </>
  );
}

export default Navbar;
